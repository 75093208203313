import React from "react";
import Menu from "../components/navbar";
import { Link } from "gatsby";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { Helmet } from "react-helmet";

export default function Diensten() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VDB Consulting contact</title>
        <meta name="format-detection" content="telephone=no"></meta>
      </Helmet>
      <Menu />
      <div className="diensten">
        <div className="dienstenIntro">
          <h2>Diensten</h2>
          <p>
            VDB Consulting/VDB Recherchebureau i.o. heeft een breed scala aan
            diensten. Dit geldt zowel voor de bedrijfs- als ook voor de
            particuliere sector. Ook verzorgen wij integere lezingen over het
            werk van de familierechercheur in strafrechtelijke onderzoeken en
            het identificatieproces MH-17. Tot slot kunnen wij u van dienst zijn
            bij arbeidsconflicten waarbij zowel het bedrijfsbelang alsmede het
            persoonlijk belang niet uit het oog wordt verloren.
          </p>

          <Link href="/contact">Contact opnemen</Link>
        </div>
        <div className="dienstenScroll">
          <div className="card" id="advies">
            <h2>Advies</h2>
            <p>
              <ul>
                <li>Voorkomen bedrijfsonregelmatigheden</li>
                <li>Onderkennen van bedrijfsonregelmatigheden</li>
                <li>Sociale veiligheid</li>
                <li>Toepassen preventieve en/of repressieve maatregelen</li>
                <li>Uitvoeren interne onderzoekshandelingen</li>
                <li>Plaatsen technische hulpmiddelen</li>
                <li>Observatie</li>
              </ul>
            </p>
            <Link to="#onderzoek">
              <BsFillArrowDownCircleFill className="icon" />
            </Link>
          </div>
          <div className="card" id="onderzoek">
            <h2>Onderzoek</h2>
            <p>
              Vanaf 01-08-2024 kunnen wij u van dienst zijn met onderstaande
              diensten:
              <ul>
                <h4>Bedrijfsrecherche:</h4>
                <li>Voorkomen bedrijfsonregelmatigheden</li>
                <li>Onderkennen van bedrijfsonregelmatigheden</li>
                <li>Toepassen preventieve en/of repressieve maatregelen</li>
                <li>Uitvoeren interne onderzoekshandelingen</li>
                <li>Plaatsen technische hulpmiddelen</li>
                <li>Observatie</li>
              </ul>
              <ul>
                <h4>Particulier Onderzoek:</h4>
                <li>Afpersing</li>
                <li>Alimentatieonderzoek</li>
                <li>Oplichting</li>
                <li>Overspel</li>
                <li>Stalking</li>
                <li>Vermissing</li>
              </ul>
            </p>
            <Link to="#lezingen">
              <BsFillArrowDownCircleFill className="icon" />
            </Link>
          </div>
          <div className="card" id="lezingen">
            <h2>Lezingen</h2>
            <p>
              Wij verzorgen lezingen over het werk van de familierechercheur in
              strafrechtelijke onderzoeken en/of het identificatieonderzoek
              MH17. Een combinatie van deze lezingen is mogelijk. Voorkeur gaat
              uit om de lezingen te verzorgen voor kleine gezelschappen. Bij
              beide lezingen staat het respect voor de slachtoffers en de
              nabestaanden voorop.
            </p>
            <Link to="#bemiddeling">
              <BsFillArrowDownCircleFill className="icon" />
            </Link>
          </div>
          <div className="card" id="bemiddeling">
            <h2>Bemiddeling</h2>
            <p>
              Bemiddelen bij arbeidsconflicten zonder daarbij het bedrijfsbelang
              en/of het persoonlijk belang uit het oog te verliezen.
              Uitgangspunt is om kostenbesparend te zijn voor uw onderneming.
            </p>
            <Link to="#advies">
              <BsFillArrowUpCircleFill className="icon" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
